import { DEFAULT_LANGUAGE, LANGUAGES } from '../i18n';

export const ANSWER_VALUES = {
  CORRECT: 1,
  INCORRECT: 0,
};

const HIDDEN_QUESTIONS = [2, 8, 10];

let lastId = 0;
// Appends a property `id` to each question object and it's possibleAnswers
const appendIds = (questions) =>
  questions
    .filter((q, questionIndex) => !HIDDEN_QUESTIONS.includes(questionIndex + 1))
    .map((question) => ({
      ...question,
      // eslint-disable-next-line
      id: lastId++,
      possibleAnswers: question.possibleAnswers.map((answer) => ({
        ...answer,
        // eslint-disable-next-line
        id: lastId++,
      })),
    }));

const getAll = async (locale = DEFAULT_LANGUAGE) => {
  const shortLocale = locale.substr(0, 2);

  let safeLocale = LANGUAGES.includes(shortLocale)
    ? shortLocale
    : DEFAULT_LANGUAGE;

  if (!LANGUAGES.includes(shortLocale)) {
    console.warn(
      `Locale ${shortLocale} is currently not supported, will use fallback: ${DEFAULT_LANGUAGE}`
    );
  }

  const { default: questions } = await import(
    `../locales/${safeLocale}/quiz.js`
  );

  return appendIds(questions);
};

const QuestionService = {
  getAll,
};

export default QuestionService;
